import React, { useEffect, useState } from 'react';
import "./App.css";

import PreviewPage from "./components/PreviewPage";
import Header from "./PreviewHeader";
import { markelComponents } from './pages.js';

function Example() {
  const includeIds = [
    "top",
    "homehero",
    "homeleft",
    "homeright",
    "callout",
    "footer",
  ];
  const [filteredComponents, setFilteredComponents] = useState([]);

  useEffect(() => {
    // Filter components whenever markelComponents or includeIds change
    const newFilteredComponents = markelComponents.filter(component => includeIds.includes(component.id));
    setFilteredComponents(newFilteredComponents);
  }, [markelComponents]); // Add includeIds to the dependency array if it's dynamic

  return (
    <>
      <Header />
      <div className="relative mx-auto flex w-full max-w-8xl flex-auto justify-center">
        <div className="min-w-0 flex-auto px-4 sm:px-6 lg:px-8 py-12 lg:max-w-none !p-0">
          {filteredComponents.map((component) => (
            <PreviewPage
              key={component.id}
              border={false}
              name={component.name}
              html={component.html}
              id={component.id}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Example;