import React, { useEffect, useState } from "react";

const LoadingSpinner = () => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="z-[10000] fixed w-full h-full left-0 top-0 bg-black bg-opacity-50 flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        fill="#000"
        version="1.1"
        className="animate-spin opacity-50"
        viewBox="0 0 26.349 26.35"
        xmlSpace="preserve"
      >
        <circle fill="white" cx="13.792" cy="3.082" r="3.082"></circle>
        <circle fill="white" cx="13.792" cy="24.501" r="1.849"></circle>
        <circle fill="white" cx="6.219" cy="6.218" r="2.774"></circle>
        <circle fill="white" cx="21.365" cy="21.363" r="1.541"></circle>
        <circle fill="white" cx="3.082" cy="13.792" r="2.465"></circle>
        <circle fill="white" cx="24.501" cy="13.791" r="1.232"></circle>
        <path
          fill="white"
          d="M4.694 19.84a2.155 2.155 0 000 3.05 2.155 2.155 0 003.05 0 2.155 2.155 0 000-3.05 2.146 2.146 0 00-3.05 0z"
        ></path>
        <circle fill="white" cx="21.364" cy="6.218" r="0.924"></circle>
      </svg>
      <div className="absolute bottom-4 opacity-50 right-4 text-white text-sm">
        {counter} sec
      </div>
    </div>
  );
};

export default LoadingSpinner;