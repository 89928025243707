import React, { useState, useEffect } from "react";
import "./App.css";
import PreviewEditable from "./components/PreviewEditable";
import LoadingSpinner from "./LoadingSpinner";
import Header from "./Header";
import SplashHeader from "./SplashHeader";
import PreviewHeader from "./PreviewHeader";
import Nav from "./Nav";
import { markelComponents } from "./pages.js";
import SelectedSectionsPanel from "./SelectedSectionsPanel";

const { Configuration, OpenAIApi } = require("openai");

function App() {
  const [openaitmp, setOpenaitmp] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [doImages, setDoImages] = useState(false);

  const [gptLoading, setGptLoading] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  const [refreshCounter, setRefreshCounter] = useState(0);

  const [previewMode, setPreviewMode] = useState(false);
  const [splashMode, setSplashMode] = useState(true);
  const [checkedSections, setCheckedSections] = useState({}); // New state for tracking checked sections

  useEffect(() => {
    const configuration = new Configuration({
      apiKey: "sk-proj-P6ZRL4oE8VWLHkmPgJdtT3BlbkFJhotNJ6nc2i2tppFXNLz2",
    });
    const openaiInstance = new OpenAIApi(configuration);
    setOpenaitmp(openaiInstance);
    console.log("GPT ready");
  }, []);

  useEffect(() => {
    refreshTrigger();
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [previewMode]); // Dependency array includes previewMode to re-run the effect when it changes

  const handleCheckboxChange = (id) => {
    setCheckedSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleReorderSections = (newOrder) => {
    setCheckedSections(newOrder);
  };

  const refreshTrigger = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  const triggerGlobalGPT = async (prompt) => {
    const newArray = [];
    mergedComponents.forEach((item) => {
      const newObj = { [item.id]: item.defaults };
      newArray.push(newObj);
    });

    const checkedComponents = mergedComponents.filter(
      (item) => checkedSections[item.id]
    );
    let onearray = checkedComponents.map((item) => ({
      [item.id]: item.defaults,
    }));

    // let onearray = [];
    // // onearray.push(newArray[0]);

    // onearray.push(newArray[1]);
    // onearray.push(newArray[2]);
    // onearray.push(newArray[3]);
    // onearray.push(newArray[4]);

    //, user https://picsum.photos/1200/600 for images

    setGptLoading(true);
    try {
      const response = await testGPT([
        {
          role: "user",
          content:
            "This is json structure for a landing page component. Please replace all copy for a website that is " +
            prompt +
            ", think about hierarchy and try and make the copy the same length as the placeholders. Please do not update links" + (doImages ? " and use https://picsum.photos/1200/600 for images " : "or images") + ", please  provide a response as JSON in the exact same format. " +
            JSON.stringify(onearray),
        },
      ]);
      if (response) {
        try {
          const parsedData = JSON.parse(response);
          if (parsedData) {
            const combinedObject = parsedData.reduce(
              (acc, obj) => ({ ...acc, ...obj }),
              {}
            );

            updateComponentData(combinedObject);
          } else {
            console.error("Returned data is not in the expected format.");
          }
        } catch (parseError) {
          console.error("Error parsing GPT response:", parseError);
        }
      }
    } catch (error) {
      console.error("Error with GPT request:", error);
    }

    setPreviewMode(true);

    setTimeout(() => refreshTrigger(), 1000);

    setTimeout(() => setGptLoading(false), 2000);
  };

  const [mergedComponents, setMergedComponents] = useState([]);

  const mergeDefaultData = (html, defaults) => {
    if (!defaults) {
      return html;
    } else {
      let mergedHtml = html;
      for (const [key, value] of Object.entries(defaults)) {
        const placeholder = `\\[\\[${key}\\]\\]`;
        mergedHtml = mergedHtml.split(new RegExp(placeholder, "g")).join(value);
      }
      return mergedHtml;
    }
  };

  useEffect(() => {
    const initialMergedComponents = markelComponents.map((component) => ({
      ...component,
      mergedHtml: mergeDefaultData(component.html, component.defaults),
    }));
    setMergedComponents(initialMergedComponents);

    const initialCheckedSections = initialMergedComponents.reduce(
      (acc, component, index) => {
        acc[component.id] =
          index < 5 || index === initialMergedComponents.length - 1; // Mark the first 4 and the last section as checked
        return acc;
      },
      {}
    );
    setCheckedSections(initialCheckedSections);
  }, []);

  async function testGPT(messageIn) {
    const completion = await openaitmp.createChatCompletion({
      model: "gpt-4",
      messages: messageIn,
    });
    return completion.data.choices[0].message.content;
  }

  async function testDaLLe(messageIn) {
    try {
      const completion = await openaitmp.createImage({
        prompt: "a tiger",
        n: 1,
      });
      return completion.data[0].url;
    } catch (error) {
      console.error("Error generating image with DALL·E:", error);
      return null;
    }
  }

  const updateComponentData = (updates) => {
    setMergedComponents((prevComponents) =>
      prevComponents.map((component) => {
        const newData = updates[component.id];
        if (newData) {
          return {
            ...component,
            mergedHtml: mergeDefaultData(component.html, {
              ...component.defaults,
              ...newData,
            }),
            defaults: { ...component.defaults, ...newData },
          };
        }
        return component;
      })
    );
  };

  if (mergedComponents.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {!previewMode ? (
        splashMode ? (
          <SplashHeader
            setSplashMode={setSplashMode}
            gptLoading={gptLoading}
            prompt={prompt}
            setPrompt={setPrompt}
            triggerGlobalGPT={triggerGlobalGPT}
            setPreviewMode={setPreviewMode}
            previewMode={previewMode}
          />
        ) : (
          <Header
            triggerRefresh={refreshTrigger}
            checkedSections={checkedSections}
            showOrder={showOrder}
            setShowOrder={setShowOrder}
            setSplashMode={setSplashMode}
            setShowSplash={setSplashMode}
            gptLoading={gptLoading}
            prompt={prompt}
            setPrompt={setPrompt}
            triggerGlobalGPT={triggerGlobalGPT}
            setPreviewMode={setPreviewMode}
            previewMode={previewMode}
          />
        )
      ) : (
        <PreviewHeader
          setSplashMode={setSplashMode}
          setPreviewMode={setPreviewMode}
          gptLoading={gptLoading}
          prompt={prompt}
          setPrompt={setPrompt}
          triggerGlobalGPT={triggerGlobalGPT}
          previewMode={previewMode}
        />
      )}
      {gptLoading && <LoadingSpinner />}
      <div className="relative mx-auto flex w-full max-w-8xl flex-auto justify-center">
        <Nav pages={mergedComponents} setDoImages={setDoImages} doImages={doImages} />
        <div
          className={
            !previewMode
              ? "min-w-0 flex-auto flex flex-col px-4 sm:px-6 lg:px-8 py-12 lg:max-w-none"
              : "w-full flex flex-col"
          }
        >
          {mergedComponents.map((component) => (
            <article
              style={{
                order: previewMode
                  ? Object.keys(checkedSections).indexOf(component.id) >= 0 &&
                    checkedSections[component.id]
                    ? Object.keys(checkedSections).indexOf(component.id)
                    : 100
                  : 0,
              }}
              className={`${
                !checkedSections[component.id] ? "opacity-100" : ""
              } ${!previewMode ? "mb-[30px] rounded-md bg-white p-6" : ""}`}
              key={component.id}
              id={component.id}
            >
              {!previewMode && (
                <header
                  onClick={() => handleCheckboxChange(component.id)} // Add onClick here for the entire div
                  className="mb-2 space-y-1 text-left cursor-pointer"
                >
                  <p className="font-display text-md text-[#E8540D]">
                    Component
                  </p>
                  <div className="flex w-full justify-between">
                    <h1 className="font-display text-4xl text-[#424242] dark:text-white">
                      {component.name}
                    </h1>
                    {checkedSections[component.id] ? (
                      <div className="group inline-block">
                        <button className="bg-[#E8540D] text-white p-2 px-2 rounded ml-2 max-h-[42px] hover:bg-red-500">
                          <span className="group-hover:hidden">Included</span>
                          <span className="hidden group-hover:inline">
                            Remove
                          </span>
                        </button>
                      </div>
                    ) : (
                      <button className="bg-neutral-400 text-white p-2 px-2 rounded ml-2 max-h-[42px] ">
                        Add
                      </button>
                    )}
                  </div>

                  {/* Checkbox for each section */}
                  <input
                    type="checkbox"
                    className="!hidden"
                    checked={checkedSections[component.id] || false}
                    onChange={(e) => {
                      e.stopPropagation(); // Prevent event from bubbling up to the article onClick
                      handleCheckboxChange(component.id);
                    }}
                  />
                </header>
              )}
              <div
                className={
                  !previewMode
                    ? "text-left prose prose-neutral max-w-none dark:prose-invert prose-headings:scroll-mt-28 prose-headings:font-display prose-headings:font-normal lg:prose-headings:scroll-mt-[8.5rem] prose-lead:text-neutral-500 dark:prose-lead:text-neutral-400 prose-a: dark:prose-a:text-sky-400 prose-a:no-underline prose-a:shadow-[inset_0_-2px_0_0_var(--tw-prose-background,#fff),inset_0_calc(-1*(var(--tw-prose-underline-size,4px)+2px))_0_0_var(--tw-prose-underline,theme(colors.sky.300))] hover:prose-a:[--tw-prose-underline-size:6px] dark:[--tw-prose-background:theme(colors.slate.900)] dark:prose-a:shadow-[inset_0_calc(-1*var(--tw-prose-underline-size,2px))_0_0_var(--tw-prose-underline,theme(colors.sky.800))] dark:hover:prose-a:[--tw-prose-underline-size:6px] prose-pre:rounded-xl prose-pre:bg-neutral-900 prose-pre:shadow-lg dark:prose-pre:bg-neutral-800/60 dark:prose-pre:shadow-none dark:prose-pre:ring-1 dark:prose-pre:ring-neutral-300/10 dark:prose-hr:border-neutral-800"
                    : checkedSections[component.id]
                    ? "  "
                    : " hidden "
                }
              >
                {!previewMode && (
                  <p className="block w-full mb-[0px]">
                    Lorem ipsum dolor sit amet.
                  </p>
                )}
                <PreviewEditable
                doImages={doImages}
                  refreshCounter={refreshCounter}
                  testGPT={testGPT}
                  previewMode={previewMode}
                  testDaLLe={testDaLLe}
                  border={component.border}
                  fields={component.fields}
                  defaults={component.defaults}
                  name={component.name}
                  html={component.mergedHtml}
                  id={component.id}
                  onUpdate={(newData) =>
                    updateComponentData({
                      [component.id]: newData,
                    })
                  }
                />
              </div>
            </article>
          ))}
        </div>
      </div>

      {(showOrder || previewMode) && (
        <SelectedSectionsPanel
          refreshTrigger={refreshTrigger}
          checkedSections={checkedSections}
          onReorder={handleReorderSections}
          setShowOrder={setShowOrder}
          previewMode={previewMode}
        />
      )}
    </>
  );
}

export default App;
