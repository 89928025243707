import React, { useEffect, useState } from "react";
import "./App.css";

import PreviewPage from "./components/PreviewPage";
import Header from "./PreviewHeader";
import { markelComponents } from "./pages.js";

function Technology() {
  const includeIds = [ "homehero","callout", "tabs"]; // IDs to include

  const [filteredComponents, setFilteredComponents] = useState([]);


  useEffect(() => {
    const newFilteredComponents = includeIds
      .map(id => markelComponents.find(component => component.id === id))
      .filter(component => component !== undefined);
  
    setFilteredComponents(newFilteredComponents);
  }, [markelComponents, includeIds]);


  const [shouldFadeOut, setShouldFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Creating a new event for resize
      const event = new Event('resize');
      // Dispatching the resize event
      window.dispatchEvent(event);
      setShouldFadeOut(true);
    }, 250); // 5 seconds delay
  
    // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this effect runs once on mount


  return (
    <>
        <div className={`blocker w-full h-full fixed bg-off-white z-[999] ${shouldFadeOut ? 'fadeOut' : ''}`}></div>

      <Header />
      <div className="relative mx-auto flex w-full max-w-8xl flex-auto justify-center">
        <div className="min-w-0 flex-auto px-4 sm:px-6 lg:px-8 py-12 lg:max-w-none !p-0">
          {filteredComponents.map((component) => (
            <PreviewPage
              key={component.id}
              border={false}
              name={component.name}
              html={component.html}
              id={component.id}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Technology;
