import React from "react";

const Header = ({setPreviewMode, setSplashMode}) => {
  return (
    <div className="w-[350px] hidden lg:block fixed left-[-50px] bg-off-white z-[999] h-[calc(100%_+400px)] mt-[-200px] pt-[200px] text-center py-4 text-[#424242] shadow-custom-inset">
      <a
        href="#"
        className="!hidden block flex items-center justify-center w-full h-[140px]"
        onClick={(e) => {
          e.preventDefault();
          window.location.reload();
        }}
      >
        <svg
          className="max-w-[120px] sm:max-w-[170px] w-full ml-[50px]"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 361.6 79.5"
          xmlSpace="preserve"
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "\n                     .st0 {\n                     fill: #424242;\n                     }\n                     .st1 {\n                     clip-path: url(#SVGID_00000166658948291180124270000001206133068232654217_);\n                     fill: #f05123;\n                     }\n                     * {\n                     }\n                     * {\n                     }\n                  ",
            }}
          />
          <path
            className="st0"
            d="M318.8,66.7V59h-24.3V47.8h19.9v-7.2h-19.9V30h24.4v-7.7h-33.3v44.4H318.8z M360.6,66.7V59H337V22.3h-8.9v44.4 H360.6z M276.9,66.7l-18.2-25.5l17.8-18.9h-10.8l-17.4,18.2h-3V22.3h-8.9v44.4h8.9V47.8h8.1l13.5,18.9H276.9z M215.6,49.9 c0.7-0.1,1.3-0.2,2-0.4c6.3-1.8,9.9-6.5,9.9-13.2c0-8.5-6.2-14-15.7-14h-23.4v44.4h8.9V50.3h8.8l10.5,16.4h10.1L215.6,49.9z M197.3,30h13.4c5.3,0,7.9,2,7.9,6.1c0,4.1-2.8,6.4-7.8,6.4h-13.5V30z M97.7,53.5L85.8,34.9v31.8h-8.7V22.3h10.4l14.2,22.8h0.6 l14.2-22.8h10.4v44.4h-8.7V34.9l-11.9,18.6H97.7z M181.8,66.7h-9.7l-3.7-8.8h-21.8l-3.7,8.8h-9.7l20-44.4h8.6L181.8,66.7z M165.1,50.1l-7.3-17.2h-0.8l-7.3,17.2H165.1z"
          />
          <g>
            <g>
              <defs>
                <rect
                  id="SVGID_1_"
                  x="-29.1"
                  y="-24.1"
                  width="121.5"
                  height="121.5"
                />
              </defs>
              <clipPath id="SVGID_00000055697179233322780830000012005766994752809363_">
                <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
              </clipPath>
              <path
                style={{
                  clipPath:
                    'url("#SVGID_00000055697179233322780830000012005766994752809363_")',
                  fill: "rgb(240, 81, 35)",
                }}
                d="M19.8,67H1.3V30.3 L19.8,23V67z M40.9,14.6L22.4,22V67h18.5V14.6z M62,6.3l-18.5,7.3V67H62V6.3z"
              />
            </g>
          </g>
        </svg>


        
      </a>

      <img onClick={(e) => {setSplashMode(false);setPreviewMode(false)}} src = "/fakemenu.png" className="pl-[50px] w-full"/>
    </div>
  );
};

export default Header;
