import React, { useEffect, useRef, useState } from "react";

const PreviewPage = ({ id, html, border }) => {
  const iframeRef = useRef(null);
  const [height, setHeight] = useState("500px");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const iframe = iframeRef.current;

    const sendHeightToParent = () => {
      const iframeDocument = iframe.contentDocument;
      if (iframeDocument) {
        let pageSection = iframeDocument.querySelector(".page-section");

        if (!pageSection) {
          pageSection = iframeDocument.querySelector(".mkl-banner");
        }

        if (pageSection) {
          const pageSectionHeight = pageSection.scrollHeight;
          window.parent.postMessage({ id, pageSectionHeight }, "*");
        }
      }
    };

    if (iframe) {
      const iframeDocument = iframe.contentDocument;
      const head = iframeDocument.head;
      const body = iframeDocument.body;

      const cssLink = iframeDocument.createElement("link");
      cssLink.href =
        "https://unbouncemarkel.s3.eu-west-2.amazonaws.com/autodeploy/components-v20240626075239/markelstyles.css";
      cssLink.rel = "stylesheet";
      head.appendChild(cssLink);

      const style = iframeDocument.createElement("style");
      style.textContent =
        "body { overflow-x: hidden; background:#F4F2ED;padding-left:300px; }";
      head.appendChild(style);

      try {
        body.innerHTML = html;

        sendHeightToParent();

        iframe.onload = () => {
          setLoading(false);
          setTimeout(sendHeightToParent, 0);
        };
      } catch (error) {
        console.error("Error setting innerHTML:", error);
      }
    }

    const handleResize = () => {
      sendHeightToParent();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id, html]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.id === id && event.data.pageSectionHeight) {
        setHeight(event.data.pageSectionHeight + "px");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [id]);

  return (
    <div
      className={`w-full pointer-events-none rounded- overflow-hidden my-0 ${
        border ? "border-[10px] border-[#e3e0db]" : ""
      }`}
    >
      {loading && (
        <div className="flex items-center justify-center w-full h-full min-h-[400px]">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        </div>
      )}
      <iframe
        key={id}
        style={{ height: height, display: loading ? "none" : "block" }}
        className="w-full"
        ref={iframeRef}
        title={`My Frame ${id}`}
      />
    </div>
  );
};

export default PreviewPage;
