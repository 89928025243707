import React, { useEffect, useRef, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

const PreviewEditable = ({
  id,
  html,
  border,
  onUpdate,
  fields,
  defaults,
  testGPT,
  previewMode,
  testDaLLe,
  refreshCounter,
  doImages
}) => {
  const iframeRef = useRef(null);
  const [height, setHeight] = useState("500px");
  const [showEditor, setShowEditor] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gptLoading, setGptLoading] = useState(false);
  const [fieldValues, setFieldValues] = useState(defaults);
  const [prompt, setPrompt] = useState("");

  const handleInputChange = (fieldName, value) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };

  const copyJSON = () => {
    setShowEditor(false);

    let json = { [id]: fieldValues };
    alert(JSON.stringify(json, null, 2));
  };

  const handleSubmit = () => {
    setShowEditor(false);
    onUpdate(fieldValues);
  };

  const handleEdit = () => {
    setShowEditor(true);
  };

  useEffect(() => {
    // Your resize logic here
    console.log("Triggering resize logic due to refreshCounter change");

    getHeightFromChild();

    // Example resize logic
    window.dispatchEvent(new Event("resize"));
  }, [refreshCounter]); // Dependency array, effect runs when refreshCounter changes

  const getHeightFromChild = () => {
    if (iframeRef.current && iframeRef.current.contentWindow.document) {
      const iframeDocument = iframeRef.current.contentWindow.document;

      let pageSection = iframeDocument.querySelector(".page-section");

      if (!pageSection) {
        pageSection = iframeDocument.querySelector(".mkl-banner");
      }

      if (pageSection) {
        const pageSectionHeight = pageSection.scrollHeight;
        console.log("Page section height:", pageSectionHeight);
      }
    }
  };

  const handleTestDaLLe = async () => {
    let json = { [id]: fieldValues };
    setGptLoading(true);
    try {
      const response = await testDaLLe("a tiger in the jungle");
      console.log(response);
    } catch (error) {
      console.error("Error with DALL·E request:", error);
    }
    setGptLoading(false);
  };

  const handleTestGPT = async (prompt) => {
    let json = { [id]: fieldValues };
    setGptLoading(true);
    try {
      const response = await testGPT([
        {
          role: "user",
          content:
            "This is json structure for a landing page component. Please replace all copy for a website that is " +
            prompt +
            ", think about hierarchy and try and make the copy the same length as the placeholders. Please do not update links " + (doImages ? " and use https://picsum.photos/1200/600 for images " : "or images") + ", please provide a response as JSON in the same format. " +
            JSON.stringify(json),
        },
      ]);
      if (response) {
        try {
          const parsedData = JSON.parse(response);
          if (parsedData && parsedData[id]) {
            setFieldValues(parsedData[id]);
            onUpdate(parsedData[id]);
          } else {
            console.error("Returned data is not in the expected format.");
          }
        } catch (parseError) {
          console.error("Error parsing GPT response:", parseError);
        }
      }
    } catch (error) {
      console.error("Error with GPT request:", error);
    }
    setGptLoading(false);
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    const sendHeightToParent = () => {
      const iframeDocument = iframe.contentDocument;
      if (iframeDocument) {
        let pageSection = iframeDocument.querySelector(".page-section");

        if (!pageSection) {
          pageSection = iframeDocument.querySelector(".mkl-banner");
        }

        if (pageSection) {
          const pageSectionHeight = pageSection.scrollHeight;
          window.parent.postMessage({ id, pageSectionHeight }, "*");
        }
      }
    };

    if (iframe) {
      const iframeDocument = iframe.contentDocument;
      const head = iframeDocument.head;
      const body = iframeDocument.body;

      const cssLink = iframeDocument.createElement("link");
      cssLink.href =
        "https://unbouncemarkel.s3.eu-west-2.amazonaws.com/autodeploy/components-v20240626075239/markelstyles.css";
      cssLink.rel = "stylesheet";
      head.appendChild(cssLink);

      const style = iframeDocument.createElement("style");
      style.textContent = "body { overflow-x: hidden; background:#F4F2ED; }";
      head.appendChild(style);

      try {
        body.innerHTML = html;

        sendHeightToParent();

        
        iframe.onload = () => {
          setLoading(false);
          setTimeout(sendHeightToParent, 0);
        };

        setTimeout(() => {
          setLoading(false);
          sendHeightToParent();
        }, 5000);
      } catch (error) {
        console.error("Error setting innerHTML:", error);
      }
    }

    const handleResize = () => {
      sendHeightToParent();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id, html]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.id === id && event.data.pageSectionHeight) {
        setHeight(event.data.pageSectionHeight + "px");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [id]);

  return (
    <div
      className={`w-full  overflow-hidden ${
        !previewMode ? "mt-6 rounded-s" : ""
      } ${!previewMode && border ? "" : ""}`}
    >
      {gptLoading && <LoadingSpinner />}
      {loading && (
        <div className="flex items-center justify-center w-full h-full min-h-[400px]">
          <div
            className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        </div>
      )}
      <iframe
        key={id}
        style={{ height: height, display: loading ? "none" : "block" }}
        className="w-full pointer-events-none"
        ref={iframeRef}
        title={`My Frame ${id}`}
      />

      <div
        className={!previewMode ? "bg-white w-full text-left pt-4" : "hidden"}
      >
        {showEditor &&
          fields &&
          Object.entries(fields).map(([fieldName, fieldType]) => (
            <div key={fieldName} className="mb-4">
              <label className="block mb-2">{fieldName}</label>
              {fieldType === "textInput" && (
                <input
                  type="text"
                  value={fieldValues[fieldName]}
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                  className="border p-2 w-full"
                />
              )}
              {fieldType === "textArea" && (
                <textarea
                  value={fieldValues[fieldName]}
                  onChange={(e) => handleInputChange(fieldName, e.target.value)}
                  className="border p-2 w-full"
                />
              )}
            </div>
          ))}
        <div className="flex w-full justify-between">
          <div>
            {" "}
            {showEditor && !gptLoading && (
              <button
                onClick={handleSubmit}
                className="bg-[#E8540D] text-white p-2 rounded"
              >
                Submit
              </button>
            )}
            {!showEditor && !gptLoading && (
              <button
                onClick={handleEdit}
                className="bg-neutral-400 text-white p-2 rounded ml-0"
              >
                Edit
              </button>
            )}
            {showEditor && !gptLoading && (
              <button
                onClick={copyJSON}
                className="bg-neutral-400 text-white p-2 rounded ml-2"
              >
                CopyJSON
              </button>
            )}
          </div>
          <div>
            {!gptLoading && (
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleTestGPT(prompt);
                  }
                }}
                className="bg-off-white w-full h-full p-3 text-neutral-400 placeholder-neutral-400 focus:outline-none md:ml-2 md:text-neutral-500"
              />
            )}

            {gptLoading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#000"
                version="1.1"
                className="animate-spin"
                viewBox="0 0 26.349 26.35"
                xmlSpace="preserve"
              >
                <circle
                  fill="#424242"
                  cx="13.792"
                  cy="3.082"
                  r="3.082"
                ></circle>
                <circle
                  fill="#424242"
                  cx="13.792"
                  cy="24.501"
                  r="1.849"
                ></circle>
                <circle fill="#424242" cx="6.219" cy="6.218" r="2.774"></circle>
                <circle
                  fill="#424242"
                  cx="21.365"
                  cy="21.363"
                  r="1.541"
                ></circle>
                <circle
                  fill="#424242"
                  cx="3.082"
                  cy="13.792"
                  r="2.465"
                ></circle>
                <circle
                  fill="#424242"
                  cx="24.501"
                  cy="13.791"
                  r="1.232"
                ></circle>
                <path
                  fill="#424242"
                  d="M4.694 19.84a2.155 2.155 0 000 3.05 2.155 2.155 0 003.05 0 2.155 2.155 0 000-3.05 2.146 2.146 0 00-3.05 0z"
                ></path>
                <circle
                  fill="#424242"
                  cx="21.364"
                  cy="6.218"
                  r="0.924"
                ></circle>
              </svg>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewEditable;
