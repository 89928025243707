import React, { useState } from 'react';

const SelectedSectionsPanel = ({ checkedSections, onReorder, setShowOrder, previewMode, refreshTrigger }) => {
  const [draggedItemId, setDraggedItemId] = useState(null);

  const handleDragStart = (e, sectionId) => {
    setDraggedItemId(sectionId);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary for allowing a drop
    e.dataTransfer.dropEffect = 'move';
  };

  const handleRemoveItem = (sectionId) => {
    const newCheckedSections = { ...checkedSections, [sectionId]: false };
    onReorder(newCheckedSections);
  };

  const handleDrop = (e, targetSectionId) => {
    e.preventDefault();
    const checkedKeys = Object.keys(checkedSections).filter(key => checkedSections[key]);
    const draggedIndex = checkedKeys.indexOf(draggedItemId);
    const targetIndex = checkedKeys.indexOf(targetSectionId);

    if (draggedIndex !== targetIndex) {
      const newOrder = [...checkedKeys];
      newOrder.splice(draggedIndex, 1); // Remove the dragged item
      newOrder.splice(targetIndex, 0, draggedItemId); // Insert it before the target item

      const newCheckedSections = newOrder.reduce((acc, key) => {
        acc[key] = checkedSections[key];
        return acc;
      }, {});

      onReorder(newCheckedSections);
    }
  };

  return (
    <div className=" text-[#424242] p-6 fixed bottom-4 right-4 w-1/4 h-auto bg-white overflow-auto p-4">
       {!previewMode && <button 
        onClick={() => setShowOrder(false)} 
        className="absolute top-1 right-2 m-2 text-md text-gray-600 hover:text-gray-800"
      >
        &#x2715; {/* This is a Unicode character for a simple 'X' */}
      </button>}
      <h3 onClick={refreshTrigger} className="text-lg font-semibold mb-2">Selected Components</h3>
      <ul>
        {Object.entries(checkedSections).filter(([_, isChecked]) => isChecked).map(([sectionId], index) => (
          <li key={sectionId} className="bg-off-white p-2 mb-2  flex items-center justify-between"
              draggable
              onDragStart={(e) => handleDragStart(e, sectionId)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, sectionId)}>
            <span>{sectionId}</span>
            <button onClick={() => handleRemoveItem(sectionId)} className="text-red-500 hover:text-red-700">-</button>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectedSectionsPanel;