import React from "react";

const Header = ({
  prompt,
  setPrompt,
  triggerGlobalGPT,
  gptLoading,
  setPreviewMode,
  previewMode,
  showOrder,
  setShowOrder,
  checkedSections,
  triggerRefresh
}) => {
  return (
    <header className="sticky bg-off-white top-0 z-10 flex flex-none flex-wrap items-center justify-between  px-4 py-6 border-b border-neutral-300 transition duration-500 dark:shadow-none sm:px-6 lg:px-8 dark:bg-transparent">
      <div className="mr-6 flex lg:hidden">
        <button type="button" className="relative" aria-label="Open navigation">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-neutral-600"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M0 80c0-8.8 7.2-16 16-16h416c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80zm0 160c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zm192 160c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16h160c8.8 0 16 7.2 16 16z"
            />
          </svg>
        </button>

        <div
          style={{
            position: "fixed",
            top: 1,
            left: 1,
            width: 1,
            height: 0,
            padding: 0,
            margin: "-1px",
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            whiteSpace: "nowrap",
            borderWidth: 0,
            display: "none",
          }}
        />
      </div>
      <div className="relative flex flex-grow basis-0 items-center max-md:justify-center">
        <a aria-label="Home page" href="/">
          <svg
            className="max-w-[120px] sm:max-w-[160px] w-full"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 361.6 79.5"
            xmlSpace="preserve"
          >
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  "\n                     .st0 {\n                     fill: #424242;\n                     }\n                     .st1 {\n                     clip-path: url(#SVGID_00000166658948291180124270000001206133068232654217_);\n                     fill: #f05123;\n                     }\n                     * {\n                     }\n                     * {\n                     }\n                  ",
              }}
            />
            <path
              className="st0"
              d="M318.8,66.7V59h-24.3V47.8h19.9v-7.2h-19.9V30h24.4v-7.7h-33.3v44.4H318.8z M360.6,66.7V59H337V22.3h-8.9v44.4 H360.6z M276.9,66.7l-18.2-25.5l17.8-18.9h-10.8l-17.4,18.2h-3V22.3h-8.9v44.4h8.9V47.8h8.1l13.5,18.9H276.9z M215.6,49.9 c0.7-0.1,1.3-0.2,2-0.4c6.3-1.8,9.9-6.5,9.9-13.2c0-8.5-6.2-14-15.7-14h-23.4v44.4h8.9V50.3h8.8l10.5,16.4h10.1L215.6,49.9z M197.3,30h13.4c5.3,0,7.9,2,7.9,6.1c0,4.1-2.8,6.4-7.8,6.4h-13.5V30z M97.7,53.5L85.8,34.9v31.8h-8.7V22.3h10.4l14.2,22.8h0.6 l14.2-22.8h10.4v44.4h-8.7V34.9l-11.9,18.6H97.7z M181.8,66.7h-9.7l-3.7-8.8h-21.8l-3.7,8.8h-9.7l20-44.4h8.6L181.8,66.7z M165.1,50.1l-7.3-17.2h-0.8l-7.3,17.2H165.1z"
            />
            <g>
              <g>
                <defs>
                  <rect
                    id="SVGID_1_"
                    x="-29.1"
                    y="-24.1"
                    width="121.5"
                    height="121.5"
                  />
                </defs>
                <clipPath id="SVGID_00000055697179233322780830000012005766994752809363_">
                  <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
                <path
                  style={{
                    clipPath:
                      'url("#SVGID_00000055697179233322780830000012005766994752809363_")',
                    fill: "rgb(240, 81, 35)",
                  }}
                  d="M19.8,67H1.3V30.3 L19.8,23V67z M40.9,14.6L22.4,22V67h18.5V14.6z M62,6.3l-18.5,7.3V67H62V6.3z"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
      <div className="-my-5 mr-0 sm:mr-0 md:mr-0">
        <div className="group min-w-[600px] flex h-6 w-6 items-center justify-center !outline-none sm:justify-start md:h-auto md:w-80 md:flex-none md:rounded-md md:py-2.5 md:pl-4 md:pr-3.5 text-md md:ring-1 md:ring-neutral-200 md:hover:ring-neutral-300 dark:md:bg-neutral-800/75 dark:md:ring-inset dark:md:ring-white/5 dark:md:hover:bg-neutral-700/40 dark:md:hover:ring-neutral-500 w-full">
          <input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            type="prompt"
            className="w-full h-full bg-transparent text-lg  text-neutral-400 placeholder-neutral-400 focus:outline-none md:ml-2 md:text-neutral-500"
            aria-label="prompt"
          />
          <button
            onClick={(e) => triggerGlobalGPT(prompt)}
            class="bg-[#E8540D] text-white p-2 px-2 rounded ml-2 max-h-[42px] "
          >
            {gptLoading ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#000"
                version="1.1"
                className="animate-spin"
                viewBox="0 0 26.349 26.35"
                xmlSpace="preserve"
              >
                <circle fill="white" cx="13.792" cy="3.082" r="3.082"></circle>
                <circle fill="white" cx="13.792" cy="24.501" r="1.849"></circle>
                <circle fill="white" cx="6.219" cy="6.218" r="2.774"></circle>
                <circle fill="white" cx="21.365" cy="21.363" r="1.541"></circle>
                <circle fill="white" cx="3.082" cy="13.792" r="2.465"></circle>
                <circle fill="white" cx="24.501" cy="13.791" r="1.232"></circle>
                <path
                  fill="white"
                  d="M4.694 19.84a2.155 2.155 0 000 3.05 2.155 2.155 0 003.05 0 2.155 2.155 0 000-3.05 2.146 2.146 0 00-3.05 0z"
                ></path>
                <circle fill="white" cx="21.364" cy="6.218" r="0.924"></circle>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 1792 1792"
              >
                <path
                  fill="white"
                  d="M1254 581l293-293-107-107-293 293zm447-293q0 27-18 45L397 1619q-18 18-45 18t-45-18l-198-198q-18-18-18-45t18-45L1395 45q18-18 45-18t45 18l198 198q18 18 18 45zM350 98l98 30-98 30-30 98-30-98-98-30 98-30 30-98zm350 162l196 60-196 60-60 196-60-196-196-60 196-60 60-196zm930 478l98 30-98 30-30 98-30-98-98-30 98-30 30-98zM990 98l98 30-98 30-30 98-30-98-98-30 98-30 30-98z"
                ></path>
              </svg>
            )}
          </button>

          {!previewMode && (
            <button
              onClick={(e) => setPreviewMode(true)}
              class="bg-neutral-400 text-white p-2 px-2 rounded ml-2 max-h-[42px] "
            >
              Preview
            </button>
          )}

          {!previewMode && (
            <button
              onClick={(e) => setShowOrder(true)}
              class="bg-neutral-400 text-white p-2 px-2 rounded ml-2 max-h-[42px] flex items-center gap-1 "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-icon"
                style={{ width: "1em", height: "1em", verticalAlign: "middle" }}
                fill="currentColor"
                overflow="hidden"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="#fff"
                  d="M256 341.333l256 128 256-128-256-128-256 128zM532.864 132.95l341.035 173.91c20.736 10.581 28.202 34.56 16.682 53.632a41.387 41.387 0 01-16.64 15.317l-341.077 173.91a46.336 46.336 0 01-41.728 0l-341.035-173.91c-20.736-10.581-28.202-34.56-16.682-53.632a41.387 41.387 0 0116.64-15.317l341.077-173.91c12.97-6.613 28.757-6.613 41.728 0zm0 587.35a45.653 45.653 0 01-41.728 0L150.101 543.36c-20.736-10.752-28.202-35.157-16.682-54.528a41.643 41.643 0 0116.64-15.573 34.901 34.901 0 0132.213 0l308.907 160.213c12.928 6.741 28.714 6.741 41.685 0l308.864-160.213a34.901 34.901 0 0132.17 0c20.737 10.752 28.203 35.157 16.683 54.528a41.643 41.643 0 01-16.64 15.573L532.864 720.299zm0 170.666a45.653 45.653 0 01-41.728 0L150.101 714.027c-20.736-10.752-28.202-35.158-16.682-54.528a41.643 41.643 0 0116.64-15.574 34.901 34.901 0 0132.213 0L491.179 804.14c12.928 6.741 28.714 6.741 41.685 0l308.864-160.214a34.901 34.901 0 0132.17 0c20.737 10.752 28.203 35.158 16.683 54.528a41.643 41.643 0 01-16.64 15.574L532.864 890.965z"
                ></path>
              </svg>
              {Object.values(checkedSections).filter(Boolean).length}
            </button>
          )}

<button
              onClick={(e) => triggerRefresh(true)}
              class="bg-neutral-400 text-white p-2 px-2 rounded ml-2 max-h-[42px] "
            >
              Refresh
            </button>

        </div>

        <div
          style={{
            position: "fixed",
            top: 1,
            left: 1,
            width: 1,
            height: 0,
            padding: 0,
            margin: "-1px",
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            whiteSpace: "nowrap",
            borderWidth: 0,
            display: "none",
          }}
        />
      </div>
      <div className="relative flex !hidden basis-0 justify-end gap-6 sm:gap-8 md:flex-grow">
        <div className="relative z-10" data-headlessui-state="">
          <label
            className="sr-only"
            id="headlessui-listbox-label-:r0:"
            data-headlessui-state=""
          >
            Theme
          </label>
          <button
            className="flex h-6 w-6 items-center justify-center rounded-lg shadow-md shadow-black/5 ring-1 ring-black/5 dark:bg-neutral-700 dark:ring-inset dark:ring-white/5"
            aria-label="Theme"
            id="headlessui-listbox-button-:r1:"
            type="button"
            aria-haspopup="listbox"
            aria-expanded="false"
            data-headlessui-state=""
            aria-labelledby="headlessui-listbox-label-:r0: headlessui-listbox-button-:r1:"
          >
            <svg
              aria-hidden="true"
              viewBox="0 0 16 16"
              className="h-4 w-4 dark:hidden fill-neutral-400"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 1a1 1 0 0 1 2 0v1a1 1 0 1 1-2 0V1Zm4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm2.657-5.657a1 1 0 0 0-1.414 0l-.707.707a1 1 0 0 0 1.414 1.414l.707-.707a1 1 0 0 0 0-1.414Zm-1.415 11.313-.707-.707a1 1 0 0 1 1.415-1.415l.707.708a1 1 0 0 1-1.415 1.414ZM16 7.999a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2h1a1 1 0 0 0 1-1ZM7 14a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1Zm-2.536-2.464a1 1 0 0 0-1.414 0l-.707.707a1 1 0 0 0 1.414 1.414l.707-.707a1 1 0 0 0 0-1.414Zm0-8.486A1 1 0 0 1 3.05 4.464l-.707-.707a1 1 0 0 1 1.414-1.414l.707.707ZM3 8a1 1 0 0 0-1-1H1a1 1 0 0 0 0 2h1a1 1 0 0 0 1-1Z"
              />
            </svg>
            <svg
              aria-hidden="true"
              viewBox="0 0 16 16"
              className="hidden h-4 w-4 dark:block fill-neutral-400"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.23 3.333C7.757 2.905 7.68 2 7 2a6 6 0 1 0 0 12c.68 0 .758-.905.23-1.332A5.989 5.989 0 0 1 5 8c0-1.885.87-3.568 2.23-4.668ZM12 5a1 1 0 0 1 1 1 1 1 0 0 0 1 1 1 1 0 1 1 0 2 1 1 0 0 0-1 1 1 1 0 1 1-2 0 1 1 0 0 0-1-1 1 1 0 1 1 0-2 1 1 0 0 0 1-1 1 1 0 0 1 1-1Z"
              />
            </svg>
          </button>
        </div>
        <a className="group" aria-label="GitHub" href="https://github.com">
          <svg
            aria-hidden="true"
            viewBox="0 0 16 16"
            className="h-6 w-6 fill-neutral-400 group-hover:fill-neutral-500 dark:group-hover:fill-neutral-300"
          >
            <path d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" />
          </svg>
        </a>
      </div>
    </header>
  );
};

export default Header;
