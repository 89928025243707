import React, { useState, useEffect } from "react";

const Nav = ({ pages, setDoImages, doImages }) => {
  const [activeId, setActiveId] = useState("");

  const handleScroll = () => {
    const sections = pages.map((page) => document.getElementById(page.id));
    const passedSections = sections.filter(
      (section) => section.getBoundingClientRect().top <= 200
    );
    const currentSection = passedSections[passedSections.length - 1];
    setActiveId(currentSection ? currentSection.id : sections[0].id);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, [pages]);

  const handleClick = (e, id) => {
    e.preventDefault();
    const yOffset = -120;
    const element = document.getElementById(id);
    const y =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
    setActiveId(id);
  };

  return (
    <div className="hidden lg:block lg:flex-none border-r border-neutral-300">
      <div className="sticky top-[83px] h-[calc(100vh-83px)] w-64 overflow-y-auto overflow-x-hidden py-12  px-4 xl:w-72 sm:px-6 lg:px-8">
        <nav className="text-base lg:text-base">
          {/* <div className="mt-20 lg:mt-0 mb-8 text-sm opacity-50">
                <button type="button">Sign out</button>
              </div> */}
          <ul role="list" className="space-y-9 ">
            <li>
              <h2 className="font-display  text-[#424242] dark:text-white">
                Introduction
              </h2>
              <ul
                role="list"
                className="mt-2 space-y-2 border-l-2 border-neutral-100 dark:border-neutral-800 lg:mt-4 lg:space-y-4 lg:border-neutral-200"
              >
                <li className="relative">
                  <a
                    className="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full  text-[#E8540D] before:bg-[#E8540D]"
                    href="/"
                  >
                    Getting started
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <h2 className="font-display  text-[#424242] dark:text-white">
                Components
              </h2>
              <ul
                role="list"
                className="mt-2 space-y-2 border-l-2 border-neutral-100 dark:border-neutral-800 lg:mt-4 lg:space-y-4 lg:border-neutral-200"
              >
                {pages.map((component) => (
                  <li className="relative" key={component.name}>
                    <a
                      className={`block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-neutral-500 before:hidden before:bg-neutral-300 hover:text-neutral-600 hover:before:block dark:before:bg-neutral-700 dark:hover:text-neutral-300 ${
                        activeId === component.id ? " !text-[#E8540D]  " : ""
                      }`}
                      href={`#${component.id}`}
                      onClick={(e) => handleClick(e, component.id)}
                    >
                      {component.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            {/* <li>
              <h2 className="font-display  text-[#424242] dark:text-white">
                Examples
              </h2>
              <ul
                role="list"
                className="mt-2 space-y-2 border-l-2 border-neutral-100 dark:border-neutral-800 lg:mt-4 lg:space-y-4 lg:border-neutral-200"
              >
                <li className="relative">
                  <div
                    className="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-neutral-500 before:hidden before:bg-neutral-300 hover:text-neutral-600 hover:before:block dark:before:bg-neutral-700 dark:hover:text-neutral-300 cursor-pointer"
                    onClick={() =>
                      window.open("home", "_blank", "width=1400,height=800")
                    }
                  >
                    Home
                  </div>
                </li>
                <li className="relative">
                  <div
                    className="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-neutral-500 before:hidden before:bg-neutral-300 hover:text-neutral-600 hover:before:block dark:before:bg-neutral-700 dark:hover:text-neutral-300 cursor-pointer"
                    onClick={() =>
                      window.open(
                        "construction",
                        "_blank",
                        "width=1400,height=800"
                      )
                    }
                  >
                    Construction
                  </div>
                </li>
                <li className="relative">
                  <div
                    className="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-neutral-500 before:hidden before:bg-neutral-300 hover:text-neutral-600 hover:before:block dark:before:bg-neutral-700 dark:hover:text-neutral-300 cursor-pointer"
                    onClick={() =>
                      window.open(
                        "technology",
                        "_blank",
                        "width=1400,height=800"
                      )
                    }
                  >
                    Technology
                  </div>
                </li>
              </ul>
            </li> */}
            <li>
              <h2 className="font-display  text-[#424242] dark:text-white">
                Settings
              </h2>
              <ul
                role="list"
                className="mt-2 space-y-2 border-l-2 border-neutral-100 dark:border-neutral-800 lg:mt-4 lg:space-y-4 lg:border-neutral-200"
              >
                <li className="relative">
                  <a
                    className="block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full text-neutral-500 before:hidden before:bg-neutral-300 hover:text-neutral-600 hover:before:block  dark:before:bg-neutral-700 dark:hover:text-neutral-300"
                    onClick={(e) => setDoImages(!doImages)}
                  >
                    {doImages ? "Replace Images" : "Do not replace Images"}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Nav;
